import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CTA = makeShortcode("CTA");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Navigation Automagic`}</h1>
    <p>{`While there are many great gatsby-starters out there for documentation, we decided
to build our own from scratch because we needed to satisfy a number of requirements
unique to decentralized projects, which include: handling multiple languages easily and
making it as easy as possible for content contributors to write and adapt the site
as they see fit without requiring them to learn React.`}</p>
    <p>{`Our philosophy is `}<strong parentName="p">{`"content creators write the website"`}</strong>{`, which means that we
have put a lot of thought and effort into how navigation, translations and 404 pages work
and how non-developers can control all these things using nothing more than mdx.`}</p>
    <h2>{`The Sidenav`}</h2>
    <p>{`Each top level section (Funding, Learn, Contribute) has it's own directory displayed
in the form of a vertical `}<a parentName="p" {...{
        "href": "https://github.com/makerdao/community-portal/tree/r2d/src/modules/navigation"
      }}>{`sidenav`}</a>{`.
This sidenav has very specific features:`}</p>
    <ol>
      <li parentName="ol">{`Auto-magically generated links based on existing file structure of top level section`}</li>
      <li parentName="ol">{`Recursively intelligent`}</li>
      <li parentName="ol">{`Support for multiple languages`}</li>
      <li parentName="ol">{`Default locale ("en") fills in the gaps for missing pages when on a different locale page`}</li>
    </ol>
    <p>{`To do this, we leverage Gatsby's file-source querying with a graphql regex filter to get every
single page in the entire content folder (this sounds expensive, but it's only run once
in the form of a static query during build time). We then pull in the edges and convert
that data into something usable in the form of a sidenav.`}</p>
    <CTA mdxType="CTA">
      <p><strong parentName="p">{`We get`}</strong>{`: uglified data in one array.`}</p>
      <p><strong parentName="p">{`We need`}</strong>{`: an array of objects that can contain recursive layer of children`}<br parentName="p"></br>{`
`}{`(i.e. the sidenav needs to look exactly like the file structure).`}</p>
    </CTA>
    <p>{`We used parts of the `}<a parentName="p" {...{
        "href": "https://github.com/hasura/gatsby-gitbook-starter"
      }}>{`hasura/gastsby-gitbook-starter`}</a>{`
as a reference for how to logically put this together.`}</p>
    <p>{`To keep it short, there's a file called `}<inlineCode parentName="p">{`Sidenav_Tree`}</inlineCode>{` that exports a method that
converts the MDX edge data into {title, slug, rawSlug} (rawSlug being the file path without it's locale)
and only returns elements for the locale specified and our currentTopSection (Learn, Contribute, Funding, etc.).
We convert this into sidenav objects for our default locale and our current locale
(if our current locale is the same as the default we don't make objects for it).
Then we merge overlap the locale files over the default locale files.`}</p>
    <p>{`After we've gotten our `}<inlineCode parentName="p">{`mergedLocaleFiles`}</inlineCode>{` we reduce all them using some of the sidenav example
from the hasura starter to generate an object `}<inlineCode parentName="p">{`items`}</inlineCode>{` that contain more items
(tl;dr: our sidenav in object/array form).`}</p>
    <p>{`Our Tree component then takes that data, and renders our `}<inlineCode parentName="p">{`Sidenav_Node`}</inlineCode>{` component by mapping
through all of the elements.`}</p>
    <p><strong parentName="p">{`Importantly`}</strong>{`, content creators can specify an `}<inlineCode parentName="p">{`order`}</inlineCode>{` in the frontmatter of their files which
is taken into account by the algorithm in `}<inlineCode parentName="p">{`Sidenav_Tree`}</inlineCode>{` and will determine the order in which links
to different pages appear in the sidenav (otherwise this would happen alphabetically, which is not
ideal). The "order" number can range from -infinity -> infinity, and pages will be ordered
relative to their siblings based on these simple rules:`}</p>
    <ol>
      <li parentName="ol">{`If order exists in a file it will always be above files without order.`}</li>
      <li parentName="ol">{`The lower the order number, the higher it is in the list. 0 -> 10 is Top -> Bottom`}</li>
      <li parentName="ol">{`If relative files have the same order (ie. human error) then they will be sorted alphabetically of their Title.`}</li>
      <li parentName="ol">{`Files without order are sorted in alphabetical order of their title (Title rule applies, localeCompare is used for non english characters).`}</li>
    </ol>
    <h2>{`Breadcrumbs`}</h2>
    <p>{`Breadcrumbs are very simple. They are aware of their location in the app, run a query to get all the MDX,
and then breaks down the data blob based on our current route to produce an array of objects to be rendered.`}</p>
    <p>{`You would think that we could just utilize the route/uri and convert those, however this solution doesn't
cover every edge case. Based on our specs, the expectation is that the Breadcrumb labels will match the
title (keeping to the Title Rule). So we HAVE to pull down their path, frontmatter, and headings to generate
the title and url for the label.`}</p>
    <p>{`It will look something like this:
`}<strong parentName="p">{`Home / Learn / Bounties / ... / ... / ... / ... / Page we're on`}</strong></p>
    <p>{`Note: If we're more than 3 levels deep in directories then our specs showed that we need to display
ellipsis in place of their full title. They are still clickable links though.`}</p>
    <h2>{`404s`}</h2>
    <p>{`Following the philosophy of "content creators write the website" means we should allow them to
write 404 pages as well. Yes, from a developer standpoint this sounds outlandish, but because our
content (specifically our translations) are all encapsulated in the content folder it should stay that way
(instead of having translators bounce into code to fix the 404 page).`}</p>
    <p>{`The solution is to have one 404.js page live in the `}<inlineCode parentName="p">{`src/pages`}</inlineCode>{` directory, which performs a static query to
pull down all `}<inlineCode parentName="p">{`404.mdx`}</inlineCode>{` pages at the top level of a locale folder, i.e. `}<strong parentName="p">{`content/locale(en)/404.mdx`}</strong>{`.
Similarly to `}<inlineCode parentName="p">{`header.mdx`}</inlineCode>{` files we then render the body of the current locale mdx content.`}</p>
    <p>{`If no 404 exists for the current locale we default to en. If no en 404 exists, we use an inline developer 404 page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      